<template>
  <div>
    <HomePageTop/>
    <div>
      <header>
        <div>物流追跡サイト</div>
      </header>
      <section>
        <div>
          ご利用になられた配送業者の追跡番号をご記入して頂けると、配送状態を確認できます。
        </div>
        <div class="flexAndCenterAndCenter lineHeight24" style="flex-direction: column;margin:20px 0 119px">
          <div>
            基本配送した翌日午後から確認でき、確認できない場合は詳細確認が必要な為、サポートスタッフまでご連絡ください。
          </div>
          <div>運送会社に問い合わせ確認を致します。</div>
        </div>
        <img src="../../assets/homePage/logisticsTracking/wl1.png" style="margin-bottom: 60px">
        <el-input placeholder="配送番号" v-model="ocsSearchValue" style="width: 900px">
          <template slot="append">
            <div @click="ocsSearch">検 索</div>
          </template>
        </el-input>
        <img src="../../assets/homePage/logisticsTracking/wl2.png" style="margin:120px 0 60px">
        <el-input placeholder="配送番号" v-model="emsSearchValue" style="width: 900px">
          <template slot="append">
            <div @click="emsSearch">検 索</div>
          </template>
        </el-input>
        <img src="../../assets/homePage/logisticsTracking/wl3.png" style="margin:120px 0 60px">
        <el-input placeholder="配送番号" v-model="kaiginSearchValue" style="width: 900px">
          <template slot="append">
            <div @click="kaiginSearch">検 索</div>
          </template>
        </el-input>
      </section>
      <footer>
        <div class="footerTitle">そのほか</div>
        <div class="footerImgListContainer">
          <div class="footerLImgContainer">
            <div class="flexAndCenter">
              <div>電子便：</div>
              <el-link href="http://www.bt-exp.com/" :underline="false" target="_blank">http://www.bt-exp.com/</el-link>
            </div>
            <img src="../../assets/homePage/logisticsTracking/dzb.jpg" alt="">
          </div>
          <div class="footerLImgContainer">
            <div class="flexAndCenter">
              <div>ZC便：</div>
              <el-link href="http://www.sagawa-exp.co.jp/" :underline="false" target="_blank">
                http://www.sagawa-exp.co.jp/
              </el-link>
            </div>
            <img src="../../assets/homePage/logisticsTracking/zcb.jpg" alt="">
          </div>
        </div>
      </footer>
    </div>
    <FootVue/>
  </div>
</template>

<script>
import FootVue from "../../components/foot/Foot.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  components: {
    HomePageTop,
    FootVue
  },
  data() {
    return {
      ocsSearchValue: '',
      emsSearchValue: '',
      kaiginSearchValue: '',
    }
  },
  methods: {
    ocsSearch() {

    },
    emsSearch() {

    },
    kaiginSearch() {

    }
  }
}
</script>

<style lang="scss" scoped>
header {
  background: url("../../assets/homePage/logisticsTracking/banner.jpg");
  background-size: 100% 100%;
  height: 350px;
  width: 100%;

  div {
    padding-top: 152px;
    text-shadow: 0 0 27px rgba(0, 0, 0, 0.4);
    font-weight: bold;
    font-size: 48px;
    text-align: center;
    color: #fff;
    letter-spacing: 15px;
  }
}

section {
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  color: #333;

  .lineHeight24 {
    line-height: 24px;
  }

  /deep/ .el-input__inner {
    height: 100px;
    width: 700px;
  }

  /deep/ .el-input-group__append {
    width: 200px;
    text-align: center;
    color: #FFFFFF;
    font-size: 24px;
    background: #B4272B;
    cursor: pointer;
    font-weight: 400;
  }
}

footer {
  padding: 80px 0;

  .footerTitle {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 80px;
    text-align: center;
  }

  .footerImgListContainer {
    width: 1012px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .footerLImgContainer {
      width: 406px;

      .flexAndCenter {
        margin-bottom: 24px;
        justify-content: center;

        .el-link {
          color: #0113FF;
          text-decoration: underline;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
